import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const AshTierPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ash/categories/category_tier.webp"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Tier List</h1>
          <h2>
            Prydwen's tier list for Ash Echoes that rates all currently
            available characters in Global and CN versions of the game.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Ash Echoes Database is currently being created!</h4>
          <p>
            We're currently gathering all necessary information for the upcoming
            launch!
          </p>
        </div>
      </div>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AshTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Ash Echoes | Prydwen Institute"
    description="Prydwen's tier list for Ash Echoes that rates all currently available characters in Global and CN versions of the game."
    game="ash"
  />
);
